<template>
  <component :is='contentComponentBySessionType'
    :show-session-content-select='false'
    :show-session-today-watched-time='false'
    :show-session-speakers='false'
    :show-session-details-full='true' />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LiveSession  from '@/components/sessions/LiveSession.vue'
import VodSession   from '@/components/sessions/VodSession.vue'

export default {
  name: 'Session',
  components: {
    LiveSession,
    VodSession,
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (to !== from) {
          this.getSingleSession(this.$route.query.session_id)
          this.getSessionNotices(this.$route.query.session_id)
          this.unsubscribeFromNotices()
          this.subscribeToNotices(this.$route.query.session_id)
          this.unsubscribeFromGoto()
          this.subscribeToGoto(this.$route.query.session_id)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('sessions', [
      'selectedSessionType'
    ]),
    contentComponentBySessionType () {
      if (this.selectedSessionType === 'vod') {
        return 'vod-session'
      } else {
        return 'live-session'
      }
    }
  },
  methods: {
    ...mapActions('sessions', [
      'getSingleSession',
      'subscribeToGoto',
      'unsubscribeFromGoto',
    ]),
    ...mapActions('sessionNotices', [
      'getSessionNotices',
      'subscribeToNotices',
      'unsubscribeFromNotices',
    ])
  },


}
</script>
