<template>
  <div>
    <session-notices />
    <session-redirect-message />
    <div class='py-2 lg:py-8 relative'>
      <content-background-cover
        background-color='#F3F4F6'
        class='z-0'
        :top-offset='0' />
      <div v-if='showSessionContentSelect || showSessionTodayWatchedTime'
        class='text-right flex flex-row items-center justify-between pb-2 px-2 lg:px-0'>
        <session-content-select v-if='showSessionContentSelect' />
        <session-today-watched-time v-if='showSessionTodayWatchedTime' />
      </div>
      <div ref='sessionContentContainer'
           class='flex flex-col lg:flex-row lg:gap-x-4 lg:items-stretch lg:justify-start relative'>
        <live-video-content v-if='hasSelectedSessionContent'
          :key='selectedSessionId'
          class='flex-shrink-0 z-10 relative flex flex-row items-center justify-center'
          :style='`width: ${sessionContentContainerWidth}px; height: ${sessionContentContainerHeight}px;`' />
        <session-question v-if='!isFullscreenVideo'
          class='lg:flex-grow mt-2 lg:mt-0 z-10 lg:rounded-lg'
          :style='`height: ${sessionContentContainerHeight}px`' />
      </div>
    </div>
    <div v-if='!isFullscreenVideo'
      class='pb-64'>
      <session-sponsors-carousel class='px-2 lg:px-0 mt-2' />
      <session-other-sessions v-if='hasOtherSessions'
        class='mt-16 px-2 lg:px-0' />
      <session-details-full v-if='showSessionDetailsFull'
        class='mt-16 px-2 lg:px-0'
        :session='sessionDetails' />
      <session-details-short v-if='showSessionSpeakers'
        class='mt-16 px-2 lg:px-0' />
      <session-speakers v-if='showSessionSpeakers'
        class='mt-16 px-2 lg:px-0' />
      <program-schedule class='mt-16 px-2 lg:px-0' v-if='hasSessions' />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ContentBackgroundCover  from '@/components/ContentBackgroundCover.vue'
import LiveVideoContent        from '@/components/contents/LiveVideoContent.vue'
import ProgramSchedule         from '@/components/programs/ProgramSchedule.vue'
import SessionRedirectMessage  from '@/components/sessions/SessionRedirectMessage.vue'
import SessionNotices          from '@/components/sessions/SessionNotices.vue'
import SessionQuestion         from '@/components/sessions/SessionQuestion.vue'
import SessionOtherSessions    from '@/components/sessions/SessionOtherSessions.vue'
import SessionSpeakers         from '@/components/sessions/SessionSpeakers.vue'
import SessionSponsorsCarousel from '@/components/sessions/SessionSponsorsCarousel.vue'
import SessionDetailsShort     from '@/components/sessions/SessionDetailsShort.vue'
import SessionContentSelect    from '@/components/sessions/SessionContentSelect.vue'
import SessionTodayWatchedTime from '@/components/sessions/SessionTodayWatchedTime.vue'
import SessionDetailsFull      from '@/components/sessions/SessionDetailsFull.vue'

export default {
  name: 'LiveSession',
  props: {
    showSessionContentSelect: {
      default: true,
      type: Boolean
    },
    showSessionTodayWatchedTime: {
      default: true,
      type: Boolean
    },
    showSessionSpeakers: {
      default: false,
      type: Boolean
    },
    showSessionDetailsFull: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      sessionContentContainerWidth: 0,
    }
  },
  components: {
    SessionRedirectMessage,
    SessionNotices,
    SessionQuestion,
    SessionOtherSessions,
    SessionSpeakers,
    SessionSponsorsCarousel,
    SessionDetailsShort,
    ProgramSchedule,
    LiveVideoContent,
    SessionContentSelect,
    SessionTodayWatchedTime,
    SessionDetailsFull,
    ContentBackgroundCover,
  },
  watch: {
    'selectedSessionId': {
      handler: function (newVal) {
        if (newVal && this.showSessionDetailsFull) {
          this.getSingleSessionDetails(this.selectedSessionId)
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState([
      'isFullscreenVideo',
    ]),
    ...mapState('sessions', [
      'sessionDetails',
    ]),
    ...mapGetters('sessions', [
      'hasSessions',
      'selectedSessionContent',
      'hasSelectedSessionContent',
      'selectedSessionOtherSessions',
      'selectedSessionId'
    ]),
    ...mapGetters('events', [
      'showingEventId',
    ]),
    hasOtherSessions () {
      return (this.selectedSessionOtherSessions && this.selectedSessionOtherSessions.length > 0)
    },
    sessionContentContainerHeight () {
      return (this.sessionContentContainerWidth && this.hasSelectedSessionContent && this.selectedSessionContent.height && this.selectedSessionContent.width)
             ? Math.max(parseInt((this.sessionContentContainerWidth) * (this.selectedSessionContent.height / this.selectedSessionContent.width)), 360)
             : this.sessionContentContainerWidth * (9/16) // assuming this is the standard vimeo aspect ratio
    },
    showSessionQuestionsBox () {
      return true
    },
  },
  methods: {
    ...mapActions('sessions', [
      'getSessions',
      'getSingleSessionDetails',
    ]),
    getContainerWidth () {
      if (this.showSessionQuestionsBox && this.$refs.sessionContentContainer.clientWidth >= 1024) {
        this.sessionContentContainerWidth = this.$refs.sessionContentContainer.clientWidth * 0.7
      } else {
        this.sessionContentContainerWidth = this.$refs.sessionContentContainer.clientWidth
      }
    },
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getContainerWidth)
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getContainerWidth)
      this.getContainerWidth()
    })
    this.getSessions('remaining')
  }
}
</script>

<style lang='scss' scoped>
.spinner {
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
