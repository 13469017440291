<template>
  <div class='bg-gray-900'>
    <div v-if='showingMessageBox'
         class='absolute text-sm text-center rounded z-20 w-4/5 bg-gray-100 p-4 lg:p-8'>
      <div class='p-2 lg:p-8 text-center leading-normal whitespace-pre-line'>
        {{ selectedSessionConfigAttendingMessage }}
      </div>
      <button
        class='h-12 pl-12 pr-10 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200 flex flex-row items-center mx-auto gap-x-2'
        :style='startLiveButtonStyle'
        @click='playLiveVideo'>
        {{ selectedSessionConfigAttendingButtonText }}
        <play-icon/>
      </button>
    </div>
    <video ref='videoPlayer' class='video-js' preload='none' playsinline></video>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {PlayIcon}               from '@vue-hero-icons/outline'
import 'video.js/dist/video-js.css'
import 'videojs-landscape-fullscreen'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'
import videojs                  from 'video.js'
import dayjs                    from 'dayjs'
import WatchedVideoCacheHelpers from '@/utils/watched-video-cache-helpers'
import { createStoreon }        from 'storeon'
import { crossTab }             from '@storeon/crosstab'

export default {
  name: 'LiveVideoContent',
  components: {
    PlayIcon
  },
  data () {
    return {
      getLiveVideoPlayedTimes: null,
      showingMessageBox: true,
      liveVideoPlayer: null,
      playStartTime: null,
      videoTracker: null,
      storeViewingVideoCount: null,
      increaseViewingVideoCount: null,
      newLiveHlsUrl: '',
      retryCount: 0,
      playedTimes: null,
    }
  },
  watch: {
    'newLiveHlsUrl': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.$nextTick(() => {
            if (this.liveVideoPlayer) {
              this.liveVideoPlayer.src([{type:'application/x-mpegURL', src: newVal}])
              this.liveVideoPlayer.play()
            } else {
              this.liveVideoPlayer = videojs(this.$refs.videoPlayer, this.videoOptions)
              //set quality list - 720p and 1080p
              let qualityLevels = this.liveVideoPlayer.qualityLevels();
              qualityLevels.on('addqualitylevel', (event) => {
                if (event.qualityLevel.height < 720) {
                  event.qualityLevel.enabled = false
                  qualityLevels.removeQualityLevel(event.qualityLevel)
                }
              })

              this.liveVideoPlayer.landscapeFullscreen({
                fullscreen: {
                  enterOnRotate: false,
                  exitOnRotate: false,
                  alwaysInLandscapeMode: false,
                  iOS: true
                }
              })
              this.liveVideoPlayer.hlsQualitySelector({displayCurrentQuality: true})
              this.liveVideoPlayer.on('error', this.resetLiveHlsUrl) // set 'error' event on liveVideoPlayer
              this.liveVideoPlayer.on(['waiting', 'pause'], this.stopWatchSession)
              this.liveVideoPlayer.on('playing', this.startWatchSession)
              this.liveVideoPlayer.on('fullscreenchange', this.fullscreenUpdate)
            }
          })
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
      'showingEventId',
    ]),
    ...mapGetters('sessions', [
      'hasSelectedSessionContent',
      'selectedSessionContent',
      'selectedSessionConfigAttendingMessage',
      'selectedSessionConfigAttendingButtonText'
    ]),
    cacheKey () {
      return WatchedVideoCacheHelpers.encodeCacheKey({
        eventID: this.showingEventId,
        sessionID: this.sessionID,
        contentID: this.contentID,
        startTime: this.playStartTime
      })
    },
    trackWatchedVideoParams () {
      return {
        event_id: this.showingEventId,
        session_id: this.sessionID,
        content_id: this.contentID,
        video_player: 'videojs',
        start_time: this.playStartTime,
        played_times: this.playedTimes
      }
    },
    contentID () {
      return this.selectedSessionContent.id
    },
    sessionID () {
      return this.$route.query.session_id
    },
    autoplay () {
      return this.$route.query.autoplay
    },
    startLiveButtonStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
    videoOptions () {
      return {
        autoplay: true,
        controls: true,
        fill: true,
        html5: {
          vhs: {
            overrideNative: true,
            enableLowInitialPlaylist: true,
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false
        },
        sources: [
          {
            src: this.newLiveHlsUrl,
            type: 'application/x-mpegURL',
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('contents', [
      'getContentHlsUrl'
    ]),
    ...mapActions('sessions', [
      'startWatchSession',
      'stopWatchSession',
    ]),
    ...mapActions('watchedVideos', [
      'trackWatchedVideo',
    ]),
    ...mapMutations([
      'setIsFullscreenVideo',
    ]),
    playLiveVideo () {
      if (this.$route.query.autoplay) {
        let query = Object.assign({}, this.$route.query)
        delete query.autoplay
        this.$router.replace({ query })
      }
      this.getContentHlsUrl(this.contentID).then(resp => {
        this.newLiveHlsUrl = resp
        this.initStoreViewingVideoCount()
        this.playStartTime = dayjs().format()
        this.showingMessageBox = false
        this.getLiveVideoPlayedTimes = setInterval(() => {
          let playedArray = []
          let playedData = document.getElementsByClassName('vjs-tech')[0].played
          for (let i = 0; i < playedData.length; i++) {
            let arr = []
            arr.push(playedData.start(i))
            arr.push(playedData.end(i))
            playedArray.push(arr)
          }
          WatchedVideoCacheHelpers.queueCache({cacheKey: this.cacheKey, playedArray: playedArray})
          this.playedTimes = playedArray //update trackWatchedVideoParams played_times every 5 seconds
        }, 5000)
        this.videoTracker = setInterval(this.submitTrackWatchedVideo, 30000)
      }).catch(() => {
          this.$alert('Cannot show the live video. Please contact us.', {
            confirmButtonText: 'OK',
            type: 'warning'
          })
      })
    },
    submitTrackWatchedVideo () {
      // this.liveVideoPlayer.error(3) // to trigger decode error manually
      this.trackWatchedVideo(this.trackWatchedVideoParams)
    },
    initStoreViewingVideoCount () {
      this.increaseViewingVideoCount = store => {
        store.on('@init', () => ({ viewingVideoCount: 0 }))
        store.on('increase', ({ viewingVideoCount }) => ({ viewingVideoCount: viewingVideoCount + 1 }))
      }
      this.storeViewingVideoCount = createStoreon([
        this.increaseViewingVideoCount,
        crossTab()
      ])
      this.storeViewingVideoCount.dispatch('increase')
      this.storeViewingVideoCount.on('@changed', (store) => {
        if (store.viewingVideoCount > 1) {
          if (this.liveVideoPlayer) {
            this.liveVideoPlayer.dispose()
          }
          this.$alert('You have started a video on another tab. This tab will be redirected to the programs page after 5 seconds', {
            confirmButtonText: '확인',
            type: 'warning'
          })
          setTimeout(() => {
            window.open('Programs', '_self')
          }, 5000)
        }
      })
    },
    resetLiveHlsUrl () {
      console.error(this.liveVideoPlayer.error())
      this.retryCount += 1
      if (this.retryCount < 5) {
        this.getContentHlsUrl(this.contentID).then((resp) => {
          this.newLiveHlsUrl = resp
          this.liveVideoPlayer.src([{type:'application/x-mpegURL',
                                     src: this.newLiveHlsUrl}])
          this.liveVideoPlayer.play()
        })
      } else {
        this.$alert('Cannot show the live video. Please contact us.', {
          confirmButtonText: 'OK',
          type: 'warning'
        })
      }
    },
    fullscreenUpdate () {
      this.setIsFullscreenVideo(this.liveVideoPlayer.isFullscreen())
    },
  },
  beforeDestroy () {
    clearInterval(this.getLiveVideoPlayedTimes)
    clearInterval(this.videoTracker)
    this.stopWatchSession()
    if (this.liveVideoPlayer) {
      this.liveVideoPlayer.dispose()
    }
  },
  mounted () {
    if (this.$route.query.autoplay) {
      this.$nextTick(() => {
        this.playLiveVideo()
      })
    }
  }
}
</script>

<style type="text/css">
.vjs-play-control,
.vjs-remaining-time,
.vjs-progress-holder,
.vjs-audio-button,
.vjs-big-play-button,
.vjs-subs-caps-button,
.vjs-picture-in-picture-control {
  display: none !important;
}

.video-js .vjs-tech {
  pointer-events: none;
}
</style>
